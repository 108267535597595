<h5 class="card-header text-center pb-3">Add Voucher</h5>
<div *ngFor="let alert of successAlerts">
  <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout" >{{ alert.msg }}</alert>
</div>
<div *ngFor="let alert of failureAlerts">
  <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout" >{{ alert.msg }}</alert>
</div>
<div class="card ">
  <div class="card-body">
 <form name="form" (ngSubmit)="voucherForm.form.valid && onSubmit(voucherForm)" #voucherForm="ngForm" novalidate>
  <div class="row pb-3">
    <div class="col-md-4">     
      <label class="mb-2" for="email">Email</label>
      <input type="text" name="email_id" class="form-control" [(ngModel)]="model.email_id" #email_id="ngModel" [ngClass]="{ 'is-invalid': voucherForm.submitted && email_id.invalid }" required email autocomplete="off">
      <div *ngIf="voucherForm.submitted && email_id.invalid" class="invalid-feedback">
            <div *ngIf="email_id.errors['required']">Please enter the customer email.</div>
            <div *ngIf="email_id.errors['email']">Email must be a valid email address</div>
       </div>
    </div>
    <div class="col-md-4">
      <label class="mb-2" for="vouchercode">Voucher Code:</label>
      <input type="text" [pattern]="'^[a-zA-Z0-9]+$'" name="voucher_code" class="form-control" [(ngModel)]="model.voucher_code" #voucher_code="ngModel" [ngClass]="{ 'is-invalid': voucherForm.submitted && voucher_code.invalid }" required autocomplete="off">
      <div *ngIf="voucherForm.submitted && voucher_code.invalid" class="invalid-feedback">
          <div *ngIf="voucher_code.errors['required']">Please enter the voucher code.</div>          
          <div *ngIf="voucher_code.errors['pattern']">Please enter the valid voucher code.</div>          
      </div>
    </div>
  </div> 
  <div class="row pb-3">
    <div class="col-md-2">
      <label class="mb-2" for="amount">Amount:</label>
      <input type="text"  [pattern]="'^[0-9.]+$'" name="voucher_amount" class="form-control" [(ngModel)]="model.voucher_amount" #voucher_amount="ngModel" [ngClass]="{ 'is-invalid': voucherForm.submitted && voucher_amount.invalid }" required autocomplete="off" placeholder="£">
      <div *ngIf="voucherForm.submitted && voucher_amount.invalid" class="invalid-feedback">
           <div *ngIf="voucher_amount.errors['required']">Please enter the amount.</div>
           <div *ngIf="voucher_amount.errors['pattern']">Please enter the valid amount.</div>
       </div>
    </div>  
  </div>  
  <div class="row pb-3">
    <div class="col-md-4">
      <label class="mb-2" for="startdate">Start Date</label>
          <input type="text" name="start_date" class="form-control" [(ngModel)]="model.start_date" #start_date="ngModel" [ngClass]="{ 'is-invalid': voucherForm.submitted && start_date.invalid }"
          #dp="bsDatepicker"
          bsDatepicker [bsConfig]="startDatepickerConfig" placement="top" required autocomplete="off">
          <div *ngIf="voucherForm.submitted && start_date.invalid" class="invalid-feedback">
              <div *ngIf="start_date.errors['required']">Please select the expire date.</div>
          </div>
    </div>
    <div class="col-md-4">
      <label class="mb-2" for="expiredate">Expire Date</label>
      <input type="text" name="expire_date" class="form-control" [(ngModel)]="model.expire_date" #expire_date="ngModel" [ngClass]="{ 'is-invalid': voucherForm.submitted && expire_date.invalid }" 
      #dp="bsDatepicker"
      bsDatepicker [bsConfig]="endDatepickerConfig" placement="top" required autocomplete="off">
      <div *ngIf="voucherForm.submitted && expire_date.invalid" class="invalid-feedback">
          <div *ngIf="expire_date.errors['required']">Please select the expire date.</div>
      </div>
    </div>
    <div class="col-md-4">     
    </div>    
  </div> 
  <div class="row pb-3">
    <div class="col-md-8">
      <label class="mb-2" for="notes">Notes</label>
      <textarea type="text" name="notes" class="form-control" #notes="ngModel" [(ngModel)]="model.notes" rows="3" autocomplete="off" placeholder="Customer Name,Holiday Reference, Remarks.."></textarea>
    </div>

  </div> 
  <button class="btn btn-primary" type="submit" [disabled]="isSubmit">
    <span class="spinner-border spinner-border-sm spandisabled" role="status" aria-hidden="true" [ngClass]="{ 'spanenabled' : isSubmit===true }"></span>
    Add Voucher
  </button>
</form>
</div>
</div>
