import {
  EnvironmentConfig,
  ADConfig
} from '../environments/environment.models';

import { serviceRoutesContainer } from '../serviceroutes/serviceroutes'

var webUIResourceId: string = "6fba1cbd-c2e3-449e-8d23-05997e5ef755"

export const environment: EnvironmentConfig = {
  environmentName: 'dev',
  production: true, 
  hmr: false,
  config: {
      tenant: 'VillaPlusLtd.onmicrosoft.com',
      clientId: webUIResourceId,
      protectedResourceMap: createProtectedResourceMap(),
      redirectUri: serviceRoutesContainer.dev.projectWebBaseUrl,
      isAngular: true
  } as ADConfig,
  configIFrame: {
      tenant: 'VillaPlusLtd.onmicrosoft.com',
      clientId: webUIResourceId,
      protectedResourceMap: createProtectedResourceMap(),
      redirectUri: serviceRoutesContainer.dev.projectWebBaseUrl,
      isAngular: true
  } as ADConfig,
  services: serviceRoutesContainer.dev,
} as EnvironmentConfig;

function createProtectedResourceMap(): Map<string, Array<string>> {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("graph.microsoft.com", ['user.read']);
  protectedResourceMap.set(serviceRoutesContainer.hmr.voucherAPIBaseUrl, ['2b9ee817-b257-4127-88f0-4fd22743b93e/user_impersonation']);
  return protectedResourceMap;
}
