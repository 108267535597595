<div class="wrapper">
  <!-- Sidebar  -->
  <nav id="sidebar" [ngClass]="{ 'active': navbarOpen }">
    <div class="sidebar-header">
      <h3>Voucher Admin</h3>
    </div>

    <ul class="list-unstyled components">
      <li class="active">
        <a routerLink="add-cs-voucher"><i class="fas fa-window-maximize"></i>Add CS Voucher</a>
        <a routerLink="add-marketing-vouchers"><i class="fas fa-window-maximize"></i>Add Marketing Vouchers</a>
        <a routerLink="void-voucher"><i class="fas fa-window-maximize"></i>Void Voucher</a>
        <a routerLink="list-vouchers"><i class="fas fa-window-maximize"></i>All Vouchers</a>
      </li>
    </ul>
  </nav>

  <!-- Page Content  -->
  <div id="content">
    <nav class="navbar navbar-expand-lg navbar-light bg-light mb-0" [ngClass]="{ 'display-none': isPageNotFound}">
      <div class="container-fluid">
        <button type="button" id="sidebarCollapse" class="btn btn-secondary" (click)="toggleNavbar()">
          <i class="fas fa-align-left"></i>
          <span>{{ navbarOpen === true ? ' Show Menu' : " Hide Menu"}}</span>
        </button>
      </div>
    </nav>
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>
</div>
