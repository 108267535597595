<h5 class="card-header text-center p-2">Void Voucher</h5>
<div *ngFor="let alert of successAlerts">
  <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
</div>
<div *ngFor="let alert of failureAlerts">
  <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
</div>
<div class="card">
  <div class="card-body">
    <form name="form" (ngSubmit)="voucherForm.form.valid && onSubmit(voucherForm)" #voucherForm="ngForm" novalidate>
      <div class="row mb-3">
        <div class="col-md-4">
          <label for="vouchercode">Voucher Code:</label>
          <input type="text" [pattern]="'^[a-zA-Z0-9]+$'" name="voucher_code" class="form-control" [(ngModel)]="model.voucher_code" #voucher_code="ngModel" [ngClass]="{ 'is-invalid': voucherForm.submitted && voucher_code.invalid }" required autocomplete="off">
          <div *ngIf="voucherForm.submitted && voucher_code.invalid" class="invalid-feedback">
            <div *ngIf="voucher_code.errors['required']">Please enter the voucher code.</div>
            <div *ngIf="voucher_code.errors['pattern']">Please enter the valid voucher code.</div>
          </div>
        </div>
      </div>
      <button class="btn btn-primary" type="submit" [disabled]="disableVoidButton()">
        <span role="status" aria-hidden="true"></span>
        Void Voucher
      </button>
    </form>
  </div>
</div>
